@import "../variables";
@import "../mixins";
@import "../product-page/mixins";

#maincontent {
    #amasty-shopby-product-list {
        padding: 0 15px;
    }

    .toolbar-sorter {
        position: sticky;
        top: 0;
        z-index: 2;
        width: 100%;
        margin: 0 -15px;
        padding: 15px;
        background-color: #ffffff;
        box-sizing: content-box;
        @media screen and (max-width: 991px ) {
            z-index: 3;
        }
        @include flex(center, center);

        .action.show-filters, .custom-dropdown {
            flex: 1;
            min-width: 50%;
        }

        .sorter-action {
            display: none !important;
        }

        .sorter-label {
            display: none;
        }

        .action.show-filters {
            font-weight: 600;
            font-size: 1.2rem;
            border: 1px solid color(text, primary);
            padding: 0 12px;
            line-height: 4rem;
            background-color: #ffffff;
            box-shadow: none !important;
            border-radius: 0;
            margin-right: 15px;
        }

        +.toolbar-products {
            padding-top: 2rem;
        }
    }

    .custom-dropdown {
        position: relative;

        .selected-value {
            font-weight: 600;
            font-size: 1.2rem;
            line-height: 4rem;
            padding: 0 6px 0 12px;
            border: 1px solid color(text, primary);
            cursor: pointer;
            @include flex(center, space-between);

            .icon-right-chevron {
                display: inline-block;
                background: transparent url("../svg/arrow-small.svg") no-repeat center;
                background-size: 50%;
                @include widthHeight(20px);

                &:before {
                    content: '';
                }
                transform: rotate(180deg);

                &:before {
                    color: color(text, primary);
                }
            }
        }

        .custom-select.dropdown {
            box-sizing: border-box;
            list-style-type: none;
            margin: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            background: #ffffff;
            padding: 10px 10px 5px;
            min-width: 100%;
            transform: translateY(100%);
            @include boxShadow();

            .custom-option-item {
                white-space: nowrap;
                text-align: left;
                font-size: 1.2rem;
                font-weight: 500;
            }
        }
    }

    .custom-select.links {
        list-style-type: none;
        padding: 0;
        margin: 0;
        @include flex(center, center);

        .custom-option-item {
            margin: 0 0 0 20px;

            a {
                font-size: 1.2rem;

                &.selected {
                    font-weight: 700;
                }
            }

            &:first-child {
                margin-left: 14px;
            }
        }
    }
}

.toolbar.toolbar-products {
    width: 100%;
    @include flex(center, flex-end);
    @include flex-wrap(wrap);

    .field.limiter {
        display: initial !important;
        flex: 1;
        text-align: right;

        .label {
            font-size: 1.2rem;

            &:after {
                content: ':';
                margin-left: -2px;
            }
        }

        .limiter-text {
            display: none;
        }
    }

    #toolbar-amount {
        order: -1;
        text-align: center;
        font-size: 1.2rem;
        font-weight: 600;
    }

    &:not(.loaded){
        > .field.limiter, > #toolbar-amount, > .toolbar-sorter {
            display: none !important;
        }
    }
}


@media all and (min-width: $screen-md-min) {
    #maincontent {
        #amasty-shopby-product-list {
            padding: 0;
        }

        .toolbar.toolbar-products {
            position: relative;
            z-index: 5;
            @include flex-wrap(nowrap);

            .modes {
                display: none;
            }

            #toolbar-amount, .field.limiter, .toolbar-sorter.sorter {
                width: auto;
            }

            #toolbar-amount {
                order: 0;
                flex: 1;
                text-align: left;
                font-size: 1.4rem;
            }

            .field.limiter {
                order: 1;
                white-space: nowrap;

                .label {
                    font-size: 1.4rem;
                }
            }

            .toolbar-sorter.sorter {
                order: 2;
                margin: 0 0 0 32px;
                padding: 0 0 0 17px;
                flex: none;
                border: 1px solid #eeeeee;
                min-width: 274px;
                position: relative;

                .sorter-label {
                    display: initial;
                    font-size: 1.2rem;

                    &:after {
                        content: ':';
                    }
                }
            }

            .custom-select.links {
                .custom-option-item {
                    a {
                        font-size: 1.4rem;
                    }
                }
            }

            .custom-dropdown {
                position: static;

                .selected-value {
                    border: 0;
                    line-height: 4.4rem;
                    padding-right: 0;

                    .icon-right-chevron {
                        display: inline-block;
                        height: auto;
                        border-right: 1px solid #eeeeee;
                        background-size: 10px;
                        @include widthHeight(44px);
                    }
                }

                .custom-select.dropdown {
                    padding: 0;

                    .custom-option-item {
                        margin: 0;

                        a {
                            font-size: 1.4rem;
                            line-height: 40px;
                            display: block;
                            padding: 0 15px;
                            transition: all 200ms ease-in;

                            &:hover {
                                background-color: color(text, primary);
                                color: #ffffff;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 769px) {
    #maincontent {
        .toolbar-sorter {
            top: 72px;
        }
    }

    .page-products .columns {
        padding-top: 0;
    }
}
