@import "../variables";
@import "../mixins";


.category-view {
    margin: 0 -15px 30px;
    background-color: #ffffff;
    position: relative;

    .category-image {
        overflow: hidden;
        display: none;
        height: 340px;

        &.with-mobile {
            display: block;
            line-height: 1;
            position: relative;

            img {
                position: absolute;
                height: 100%;
                max-width: initial;
                width: auto;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
            }
        }
    }

    .category-view-wrapper {
        padding: 15px;
    }

    .category-description {
        text-align: center;
        br {
            display: none;
        }

        h1, h2, h3, h4, h5, h6 {
            margin: 0 auto 3rem;
            font-weight: 100;
            font-size: 2.4rem;
            color: color(text, primary);
        }

        p {
            font-size: 1.8rem;
            color: color(text, primary);
            line-height: 3rem;
        }

        .secondary, strong, b {
            color: color(text, secondary);
        }
    }

    .actions {
        text-align: center;

        a.action.category-btn {
            color: #ffffff;
            background-color: color(text, secondary) !important;

            &:before {
                display: none;
            }

            &:hover {
                color: #ffffff;
                background-color: color(text, secondary) !important;
            }
        }
    }

    @media all and (min-width: $screen-md-min) {
        background-color: transparent;

        .category-image {
            margin-bottom: 4.8rem;
            display: block;
            height: auto;

            &.with-mobile {
                img {
                    position: static;
                    height: auto;
                    max-width: 100%;
                    transform: none;
                }
            }
        }

        .category-view-wrapper {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 30%;
            right: 20%;
        }

        .category-description {
            margin-bottom: 9rem;
            text-align: left;

            h1, h2, h3, h4, h5, h6 {
                margin-bottom: 5rem;
                font-size: 3.6rem;
            }

            p {
                font-size: 2.4rem;
                line-height: 4.2rem;
            }
        }

        .actions {
            text-align: left;

            .action {
                text-align: center;
            }
        }
    }

    @media all and (min-width: $screen-md-min) and (max-width: $screen-laptop-max) {
        .category-image {
            min-height: 400px;

            img {
                height: 100% !important;
                object-fit: cover;
            }
        }
    }
}

.page-wrapper {
    > .category-view {
        margin: 0;
    }
}
