@mixin boxShadow() {
    box-shadow: 0px 2px 30px rgba(35, 31, 32, 0.06);
}

@mixin slickArrows {
    .slick-arrow {
        box-shadow: none;
        width: 28px;
        height: 56px;
        background: transparent url("../svg/slick-arrow.svg") no-repeat center;
        background-size: contain;
        border: 0;

        &.slick-prev {
            left: 0;
            transform: rotate(180deg) translate(0,-50%);
            transform-origin: top;
        }

        &.slick-next {
            right: 0;
        }

        &.slick-disabled {
            opacity: 0.3;
        }

        &:before {
            display: none;
        }
    }
}

@mixin slickDots {
    .slick-dots {
        > li {
            @include widthHeight(auto);

            button {
                @include widthHeight(0.8rem);
                border: 1px solid color(text, primary);
                border-radius: 50%;
                box-shadow: none;

                &:before {
                    display: none;
                }
            }
            &.slick-active {
                button {
                    background-color: color(text, primary);
                }
            }
        }
    }
}
