@import "components/mixins";
@import "components/variables";
@import "components/category-page/mixins";
@import "components/category-page/toolbar";
@import "components/category-page/filters";
@import "components/category-page/banner";

.page-wrapper {
    background-color: color(background, page);
}

.page-title-wrapper {
    @include hideElement();
}

.amscroll-loading-block{
    display: none;
}

@media all and (min-width: $screen-md-min){
    .page-layout-2columns-left {

        #maincontent {
            max-width: 1800px;
            margin: 0 auto;
        }

        .columns {
            .column.main {
                width: 76.27%;
                max-width: 360px;
                max-width: -o-calc(100% - 360px);
                max-width: -moz-calc(100% - 360px);
                max-width: -webkit-calc(100% - 360px);
                max-width: calc(100% - 360px);

                .amasty-catalog-topnav {
                    display: none;
                }
            }

            .sidebar.sidebar-main {
                width: 23.73%;
                min-width: 360px;
                min-width: -o-calc(360px);
                min-width: -moz-calc(360px);
                min-width: -webkit-calc(360px);
                min-width: calc(360px);
                padding-right: 0;
                .aw-rbslider-container{
                    max-width: 100%;
                    box-shadow: 0px 2px 30px rgba(35,31,32,0.6);
                    .uk-slideshow{
                        height: auto;
                    }
                }
            }
        }
    }
}




