@import "../variables";
@import "../mixins";


#layered-filter-block {
    position: fixed;
    padding: 30px;
    top: 0;
    left: 0;
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    transition: transform 350ms ease-in-out;
    transform: translateX(-100%);
    z-index: 5;
    @include boxShadow();

    > .filter-title {
        border: 0;
        height: auto;

        strong {
            display: none;
        }
    }

    .filter-subtitle, .filter-options {
        position: relative;
        display: block;
    }

    .filter-subtitle {
        height: auto;
        line-height: 1;
        padding: 0 10px 15px;
        border-bottom: 1px solid color(text, primary);
        font-weight: 300;
        font-size: 1.8rem;
    }

    .filter-options {
        top: 20px;
    }

    .filter-options-item {
        padding-bottom: 0;

        .filter-options-title {
            padding-top: 0;
            padding-left: 10px;
            line-height: 56px;
            font-size: 1.2rem;
            text-transform: capitalize;

            &:after {
                content: '';
                background: transparent url('../svg/arrow-small.svg') no-repeat center;
                background-size: contain;
                top: 22px;
                transform: rotate(180deg);
                @include widthHeight(10px);
            }
        }

        .filter-options-content {
            a {
                margin: 0;
            }

            .item {
                padding: 0;
                margin: 0 0 1.4rem;

                a {
                    background: transparent !important;
                    position: relative;
                    padding: 0;
                    font-size: 1.2rem;
                    color: color(text, primary);
                    font-weight: 300;
                    @include flex(center, space-between);

                    .label {
                        flex: 1;
                        text-align: left;
                    }

                    .count {
                        color: color(text, third);
                    }

                    &:before {
                        content: '';
                        display: inline-block;
                        border: 1px solid color(text, third);
                        margin-right: 1rem;
                        @include widthHeight(16px);
                    }

                    &:after {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        background-color: color(text, primary);
                        left: 2px;
                        transform: scale(0);
                        transition: transform 200ms ease-in-out;
                        @include widthHeight(12px);
                    }

                }

                .amshopby-choice{
                    display: none;
                }

                input:checked {
                    + a {
                        font-weight: 500;

                        &:after {
                            transform: scale(1);
                        }
                    }
                }

                .am_shopby_link_selected{
                    .label{
                        font-weight: 500;
                    }
                    &:after {
                        transform: scale(1);
                    }
                }
            }

            .am-swatch-wrapper.item {
                display: block;
                margin-bottom: 1.4rem !important;
            }

            .swatch-option {
                margin: 0 auto 0 0;
                float: none;
                border: 0;
                padding: 0;

                &.text {
                    background-color: transparent;
                    color: color(text, primary);
                    line-height: 1;
                    min-width: initial;
                    height: auto;
                    font-weight: 300;

                    &.selected {
                        font-weight: 500;
                    }
                }
            }
        }

        &.active {
            .filter-options-title {
                &:after {
                    transform: rotate(0);
                }
            }
        }
    }
}

.filter-active {
    #layered-filter-block {
        transform: translateX(0);

        > .filter-title {
            strong {
                display: initial;
            }
        }
    }
}

.sidebar-main {
    .filter-current {
        display: none;
    }
}

.filter-current {
    margin-top: 20px;

    .filter-current-subtitle {
        display: none;
    }

    .items {
        padding: 0;
        @include flex(center, flex-start);
        @include flex-wrap(wrap);

        .item {
            margin: 0 4px 0 0;
            padding: 0 18px;
            background-color: color(text, primary);
            height: 44px;

            a {
                position: relative;
                margin-left: 22px;

                &:after, &:before {
                    background-color: #ffffff;
                }
            }

            .amshopby-filter-name {
                display: none;
            }

            .amshopby-filter-value {
                color: #ffffff;
                font-weight: 500;
                font-size: 1.2rem;
                order: -1;

                span {
                    color: #fff;
                }

                .swatch-option {
                    border: none;
                    background-color: transparent;
                    color: #fff;
                    outline: none;
                }
            }
        }
    }
}

@media all and (min-width: $screen-md-min) {
    #layered-filter-block {
        position: static;
        transform: none;
        padding-bottom: 40px;
        @include widthHeight(initial);

        .filter-subtitle {
            font-size: 2.2rem;
            padding-bottom: 22px;
        }

        .filter-options-item {
            .filter-options-title {
                font-size: 1.4rem;

                &:after {
                    top: 24px;
                }
            }

            .filter-options-content {
                .item {
                    a {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }
}

@media all and (max-width: $screen-md-max) {
    #layered-filter-block {
        overflow: auto;
    }
}

@media screen and (min-width: $screen-sm-min) and (max-width: $screen__l) {
    .filter.active {
        .filter-title {
            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            z-index: 2;

            strong {
                box-shadow: none;
                background: none;
                border: 0;
                left: auto;
                right: 3px;
                top: 10px;
                text-decoration: none;
                color: transparent;

                &:after {
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-size: 16px;
                    line-height: inherit;
                    color: #7d7d7d;
                    content: '\e616';
                    font-family: 'luma-icons';
                    vertical-align: middle;
                    display: inline-block;
                    font-weight: normal;
                    overflow: hidden;
                    speak: none;
                    text-align: center;
                }
            }
        }
    }
}
